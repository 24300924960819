<template>
  <v-container class="fill-height loginContainer" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" sm="8">
        <v-card class="elevation-12">
          <v-toolbar color="#ffffff" dark flat>
            <v-toolbar-title class="title">
              <img src="https://kariba.autocarsgamon.com/img/kariba.2c76ce62.png">
              <h1 class="ml-5">Intranet Kariba</h1>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="error"
                     dense
                     outlined
                     type="error">
              E-Mail o Contraseña incorrectos
            </v-alert>
            <v-form @keyup.enter.native="submit">
              <v-text-field v-model="email"
                            color="#d52787"
                            dark
                            label="Login"
                            name="login"
                            prepend-icon="mdi-account"
                            type="text"/>
              <v-text-field id="password"
                            v-model="password"
                            color="#d52787"
                            label="Contraseña"
                            name="password"
                            prepend-icon="mdi-lock"
                            type="password"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="loading" color="#d52787" v-on:click="submit">Entrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const md5 = require('md5')
export default {
  name: "Login",
  data: () => ({
    email: '',
    password: '',
    loading: false,
    error: false
  }),
  mounted(){

  },
  methods: {
    submit() {
      this.loading = true;
      const hash = md5(new Date().toLocaleString())

      this.$session.start();
      this.$session.set('token', hash);
      this.$ma.setAlias('admin kariba');
      this.$ma.setUsername('promou@autocarsgamon.com');
      this.$ma.identify({userId: hash, options: {newUser: true}})
      this.$ma.setUserProperties({userId: hash, name: 'admin kariba'});
      this.$ma.trackEvent({
        category: 'Login',
        action: 'successfully login',
        properties:{version:this.version}
      });
      this.$router.push('/');
      location.reload();
      this.loading = false;

    },

  }
}
</script>

<style scoped>
.loginContainer {
  background: url('https://autocarsgamon.com/wp-content/uploads/2019/10/Autocars-Gamon-Viatgem-amb-tu.jpg') 100%;
}

.loginContainer:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.title {
  display: contents;
}

.title h1 {
  font-size: 16pt;
  color: #d52787;
}

.title img {
  width: 15%;
}
</style>